import Splide from '@splidejs/splide';

document.addEventListener('DOMContentLoaded', function () {

    var service_cards_splide = new Splide( '#service_cards_splide', {
        direction: 'ltr',
        type   : 'loop',
        drag   : true,
        arrows : false,
        gap    : '17px',
        pagination: true,
        trimSpace: false,
        autoWidth: true,
        mediaQuery: 'min',
        focus: 'center',
        breakpoints: {
            0: {
                height   : '518px',
            },
            1024: {
                height   : '723px',
            }
        }
    } );

    service_cards_splide.mount();

});
